.side-drawer {
  height: 100%;
  background: #092756;
  background: -moz-linear-gradient( 0% 100%, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -moz-linear-gradient(#670d10 0%, #092756 100%);
  background: -webkit-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba( 42, 60, 87, 0.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -o-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -ms-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: linear-gradient( to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), linear-gradient(135deg, #670d10 0%, #092756 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 45%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer a {
  text-decoration: none;
  font-size: 2rem;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}

a {
  color: #d6d7e1;
  text-decoration: none;
}

a:hover {
  color: #03e9f4;
  text-decoration: none;
}

a:active {
  color: #03e9f4;
  text-decoration: none;
}
p {
  line-height: 1.5;
}

.card-column {
  width: 100%;
  float: left;
  padding: 4%;
  box-sizing: border-box;
}

.column-1 {
  padding-top: 100px;
}

.card {
  width: 700px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #adb0c4;
  color: #092756;
  margin-bottom: 60px;
}

.border {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 6px;
  border: 1px solid #03e9f4;
  opacity: 0.5;
  left: -8px;
}

.card .card-content {
  height: 455px;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  padding-right: 20rem;
  font-size: 47px;
  font-weight: 800;
  letter-spacing: 0.2px;
  color: #03e9f4;
}

.card-text {
  color: #adb0c4;
  margin-left: 10rem;
  font-size: 20px;
  float: left;
  width: 100%;
  text-align: justify;
}

img {
  width: 40%;
  justify-content: center;
  /* padding-left: 5rem; */
}

.about-main {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  text-align: center;
}

.image-align {
  margin-top: auto;
  margin-bottom: auto;
}

.card-color {
  background-color: #888da7;
}

.background {
  background: url("../../assets/images/me.jpg");
  position: relative;
}

.layer {
  background-color: rgba(248, 247, 216, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .card-column {
    width: 90%;
  }
  .column-1 {
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
}

.toolbar {
  position: static;
  width: 100%;
  background-color: black;
  color: white;
  height: 56px;
  padding-top: 10px;
}

.toolbar__navigation {
  display: flex;
  align-items: center;
}

.toolbar__logo {
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.odd {
  color: #00ffbb;
}

.btn-resume {
  grid-row: -1;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  display: block;
  padding: 0 1em;
  height: 3em;
  line-height: 2.9em;
  min-width: 3em;
  background-color: transparent;
  border: solid 1px #03e9f4;
  color: #03e9f4;
  border-radius: 4px;
  text-align: center;
  backface-visibility: hidden;
  transition: 0.3s ease-in-out;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.btn-resume:hover {
  background-color: rgba(3, 233, 244, 0.1);
  border: 1px solid #03e9f4;
  text-shadow: none;
  text-decoration: none;
  color: #03e9f4;
}

.btn-resume:visited {
  color: #03e9f4;
  text-decoration: none;
}
/* -------------------- index.html file ------------------------- */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  width: 100%;
}

/* ------------------------- General ------------------------- */

.odd {
  color: #00ffbb;
}

.centered {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.section-title {
  text-align: center;
  text-decoration: solid;
  text-shadow: 0 0 2px;
  color: #d6d7e1;
  letter-spacing: 7px;
  text-transform: uppercase;
  font-weight: 900;
}

.line {
  display: block;
  width: 120px;
  height: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #03e9f4;
}

.center-div {
  justify-content: center;
}

/* .in-middle-about {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
} */

/* ------------------------- Navbar -------------------------- */

.bottom-nav {
  position: absolute;
  bottom: 0;
}

.top-nav {
  position: fixed;
  top: 0;
}

.nav {
  text-align: center;
  width: 100%;
  background: #092756;
  background: -moz-linear-gradient( 0% 100%, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -moz-linear-gradient(#670d10 0%, #092756 100%);
  background: -webkit-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba( 42, 60, 87, 0.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -o-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -ms-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: linear-gradient( to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), linear-gradient(135deg, #670d10 0%, #092756 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
  z-index: 1;
}

ul {
  margin: 0;
}

.nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 10px;
}

.nav ul li .nav-link:hover {
  text-decoration: none;
  color: #03e9f4;
}

.nav ul li .nav-link:active {
  color: #03e9f4;
  text-decoration: solid;
}

.nav ul li .nav-link {
  text-decoration: none;
  color: #ccc;
  text-transform: uppercase;
  font-size: 14px;
  border-style: none;
}

.nav ul li .nav-resume {
  text-decoration: none;
  font-size: 14px;
  color: #03e9f4;
  text-transform: uppercase;
  border: 1px solid #03e9f4;
}

.nav ul li .nav-resume:hover {
  background-color: rgba(3, 233, 244, 0.1);
}

.contact-email:hover {
  background-color: rgba(3, 233, 244, 0.1);
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
}

/* ------------------------- Home ---------------------------- */

.btn-home:hover {
  background-color: rgba(3, 233, 244, 0.5);
}

/* .in-middle_home {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
} */

.contents {
  color: #e4e4e4;
  /* height: 100vh; */
  text-align: center;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 15%;
  justify-content: center;
}

.line1 {
  font-size: 53px;
  color: #d6d7e1;
}

.line2 {
  font-size: 29px;
  color: #adb0c4;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.line3 {
  font-size: 20px;
  color: #adb0c4;
}

.hrTag {
  width: 20%;
  height: 2px;
  background-color: #03e9f4;
  border: 0;
}

/* ------------------------- About --------------------------- */

.contents-right {
  font-size: medium;
}

.about {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 15%;
}

/* ------------------------- Skills -------------------------- */

.container-title {
  text-align: center;
}

.skills {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 15%;
}

/* .in-middle_skills {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
} */

/* ---------------------- Education ---------------------------*/

.education {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 15%;
}

/* .in-middle_education {
  top: 95%;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
} */

.acad-year {
  text-align: center;
}

.acad-title {
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  text-decoration: none;
}

/* ---------------------- Experience ---------------------------*/

.experience {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 15%;
}

/* .in-middle_experience {
  top: 55%;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
} */

/* ----------------------- Projects ------------------------*/

.projects {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 15%;
}

/* .in-middle_projects {
  position: absolute;
  top: 90%;
  width: 100%;
  transform: translateY(-50%);
} */

/* -----------------------Contact --------------------------*/

.contact {
  position: relative;
  /* height: 100vh; */
  vertical-align: middle;
  padding-bottom: 4%;
}

.tech {
  list-style-type: disc;
}

.display {
  display: flex;
}

.sub-title {
  color: #d6d7e1;
  font-weight: 700;
}

.others {
  color: #adb0c4;
  display: block;
  padding-left: 15%;
  padding-right: 15%;
  margin-top: 3rem;
  margin-top: 3rem;
  font-size: 2rem;
  text-align: justify;
  /* font-weight: 700; */
}

/* 
.in-middle_contact {
  position: absolute;
  top: 60%;
  width: 100%;
  transform: translateY(-50%);
} */

/* .container {
  display: flex;
  flex-direction: column;
}

.element {
  background: turquoise;
  top: 50%;
  left: 50%;
  height: 50vh;
  width: 50vh;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: auto;
} */

/* ------------------------- Footer -------------------------- */

.footer {
  justify-content: center;
  text-align: center;
  padding: 2rem;
  color: #adb0c4;
}

/************************* form ************/

.btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  *margin-left: 0.3em;
}

.btn:hover, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
  background-color: #03e9f4;
}

.btn-large {
  padding: 9px 14px;
  font-size: 15px;
  line-height: normal;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.btn:hover {
  color: #03e9f4;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

.btn-primary, .btn-primary:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #03e9f4;
}

.btn-primary.active {
  color: #03e9f4;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
  filter: none;
  background-color: #03e9f4;
}

.btn-block {
  width: 100%;
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Espuma Pro Medium";
  background: #092756;
  background: -moz-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba( 42, 60, 87, 0.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -o-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -ms-radial-gradient( 0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%), -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: linear-gradient( to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%), linear-gradient(135deg, #670d10 0%, #092756 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* 1st part of last bg
-webkit-radial-gradient(
      0% 100%,
      ellipse cover,
      rgba(104, 128, 138, 0.4) 10%,
      rgba(138, 114, 76, 0) 40%
    ), */

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  height: 300px;
}

.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}

input {
  width: 100%;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}

input:focus, textarea:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4), 0 1px 1px rgba(255, 255, 255, 0.2);
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}

button[type="submit"] {
  width: 100%;
}

form {
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
}
/* -------------------------- Large Devices Only ------------------------*/

@media (min-width: 992px) {
  form {
    width: 30%;
  }
}

/********** Medium devices only **********/

@media (min-width: 768px) and (max-width: 991px) {
  /**************** home ***********/
  .line1 {
    font-size: 53px;
  }
  .line2 {
    font-size: 35px;
  }
  .line3 {
    font-size: 25px;
  }
  .contents {
    padding-top: 35%;
    padding-bottom: 25%;
  }
  .display {
    display: block;
  }
  /**************** contact ***********/
  form {
    width: 30%;
  }
  /**************** about ***********/
  .about-main {
    display: block;
  }
  .about {
    padding-bottom: 25%;
  }
  .card-text {
    margin-left: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    text-align: justify;
  }
  img {
    margin-top: 3rem;
    width: 40%;
    height: 50%;
    justify-content: center;
  }
  /*********************** skills *******/
  .skills {
    padding-bottom: 25%;
  }
  .container-skills {
    width: 90%;
  }
  .skill-set__icon {
    width: 10.6382%;
  }
  /**************** projects ************/
  .projects {
    padding-bottom: 25%;
  }
  /************* experience ******************/
  .experience {
    padding-bottom: 25%;
  }
  .education {
    padding-bottom: 25%;
  }
}

/********** Small devices only **********/

@media (min-width: 0px) and (max-width: 767px) {
  .in-middle_education {
    top: 100%;
  }
  /*********** home **************/
  .line1 {
    font-size: 38px;
  }
  .line2 {
    font-size: 20px;
  }
  .line3 {
    font-size: 17px;
  }
  .contents {
    padding-top: 20%;
    padding-bottom: 25%;
  }
  .display {
    display: block;
  }
  /**************** contact ***********/
  form {
    width: 100%;
  }
  .about {
    padding-bottom: 45%;
  }
  /************** about *************/
  .about-main {
    display: block;
  }
  .card-text {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    width: 100%;
  }
  img {
    width: 40%;
    margin-top: 3rem;
    height: 56%;
  }
  /* .in-middle-about {
    top: 57%;
  } */
  .skills {
    padding-bottom: 45%;
  }
  .container-skills {
    width: 90%;
  }
  .skill-set__icon {
    width: 17.6382%;
  }
  /************* education ******************/
  .education {
    padding-bottom: 45%;
  }
  /**************** projects ************/
  .projects {
    padding-bottom: 45%;
  }
  /************* experience ******************/
  .experience {
    padding-bottom: 45%;
  }
}
